.account-documents {
    height: calc(100vh - 128px);
    padding: 0 64px 50px;
    @include tablet-mini {
        padding: 0 15px 10px;
    }
    @include mobile-large {
        padding: 0 5px 10px;
    }
    &__content {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        border-radius: 24px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);
        @include scroll-custom;
        padding: 30px 40px;
        @include mobile-large {
            padding: 30px 5px;
        }
        input[type="text"] {
            width: 100%;
            height: 100%;
            padding-right: 150px;
            background-color: #e4e4e4;
            border: none;
            outline: none;
            padding-left: 50px;
            border-radius: 16px;
            font-size: 14px;
            font-family: Proxima;
            padding-top: 18px;
            padding-bottom: 18px;
        }
        &:before {
            position: absolute;
            content: "";
            top: 50%;
            left: 20px;
            width: 20px;
            height: 20px;
            transform: translate(0, -50%);
            background-image: (var(--test-1));
            background-repeat: no-repeat;
        }
        .btn-search__submit {
            margin-left: -138px;
            padding: 14px 47px;
            background-color: #666279;
            border: none;
            cursor: pointer;
            border-radius: 16px;
            font-size: 14px;
            font-family: Proxima;
            color: #ffffff;
        }
        .btn-share {
            padding: 14px 20px;
            border: none;
            cursor: pointer;
            border-radius: 16px;
            font-size: 14px;
            font-family: Proxima;
            color: #ffffff;
            background-color: #ef684d;
            @include tablet-max {
                width: 100%;
            }
        }
        .ant-col-xl-5 {
            @include desktop-extra {
                display: flex;
                justify-content: center;
            }
        }
        .button-able {
            background-color: #ef684d;
        }
        .button-disabled {
            background-color: #666279;
        }

        .css-2b097c-container {
            width: 20%;
        }
        .account-documents__grid {
            margin: 30px 0;
            @include mobile-large {
                margin: 30px 14px;
            }
        }
    }
}

.grid-cart {
    &__img {
        max-width: 272px;
        max-height: 272px;
        position: relative;
        img {
            width: 100%;
            display: block;
            margin-left: -30px;
            object-fit: cover;
            filter: drop-shadow(0px 10px 33px rgba(0, 0, 0, 0.25));
            padding: 7px;
        }
        .grid-cart__action {
            position: absolute;
            bottom: 27px;
            left: 0;
            height: 40px;
            width: calc(100% - 65px);
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 4px;
            &__checkbox {
                display: flex;
                justify-content: center;
                width: 100px;
                height: 100%;
                justify-content: center;
                background-color: #ffffff;
                border-radius: 10px;

                .action-checkbox__wrap {
                    display: flex;
                    align-items: center;
                    p {
                        font-size: 12px;
                        color: #808191;
                        padding-right: 10px;
                        font-family: Proxima;
                    }
                }
            }
            &__btn {
                display: flex;
                align-items: center;
                .action__share,
                .action__download {
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    cursor: pointer;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        transform: translateX(-8%);
                    }
                }
                .action__share {
                    margin-right: 5px;
                }
            }
        }
    }
    &__sub {
        padding: 0 6px;
        font-family: Proxima;
        .grid-cart-lifestyle,
        .grid-cart-date {
            h5 {
                margin-bottom: 0;
                font-size: 14px;
                color: #ef684d;
                line-height: 20px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
            }
        }
        .grid-cart-date {
            margin-top: 10px;
        }
    }
    p {
        margin-bottom: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ef684d;
        border-color: #ef684d;
    }
}

.list-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    &__check {
        display: flex;
        align-items: center;
        .list-cart__img {
            width: 120px;
            height: 120px;
            display: block;
            margin-left: 30px;
            img {
                width: 100%;
                object-fit: cover;
                filter: drop-shadow(0px 10px 33px rgba(0, 0, 0, 0.25));
            }
        }
    }
    .list-cart-lifestyle,
    .list-cart-date {
        h5 {
            margin-bottom: 0;
            font-size: 14px;
            color: #ef684d;
            line-height: 20px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
        }
    }
    .list-cart__action__btn {
        width: 150px;
        display: flex;
        align-items: center;
        .action__download {
            margin-left: 30px;
        }
        .action__share,
        .action__download {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background-color: #ffffff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.23);
            img {
                // margin-top: 5px;
                padding: 7px;
            }
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ef684d;
        border-color: #ef684d;
    }
}
