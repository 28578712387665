.input-main {
    margin-bottom: 10px;
    position: relative;
    input {
        border-radius: 0;
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #5e0f16;
        outline: none;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 32px;
        letter-spacing: normal;
        text-align: center;
        color: #5e0f16;
        font-family: Lausanne;
        margin-top: 40px;

        @include mobile-large {
            font-size: 12px;
        }

        &::placeholder {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 32px;
            letter-spacing: normal;
            color: #5e0f16;
            font-family: Lausanne;
            text-transform: uppercase;
            @include mobile-large {
                font-size: 12px;
            }
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }
    span {
        font-size: 16px;
        color: red;
        font-family: Lausanne;
        display: block;
        margin-top: 15px;
    }
}
