@mixin scroll-custom {
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    @content;
}

@mixin scroll-hidden {
    &::-webkit-scrollbar {
        width: 0;
    }
    @content;
}
