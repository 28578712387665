$small-s: 549px;
$small: 576px;
$medium: 768px;
$large: 992px;
$larger: 1024px;
$extra: 1200px;
$extra-middle: 1366px;
$extra-large: 1440px;
$supper-middle: 1600px;
$supper-large: 1920px;
$screen-ipad-pro: 1025px;
$screen-tv: 3000px;

@mixin mobile {
    @media only screen and (max-width: $small-s) {
        @content;
    }
}

@mixin mobile-large {
    @media only screen and (max-width: $small) {
        @content;
    }
}

@mixin tablet-mini {
    @media only screen and (max-width: $medium) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: $medium) {
        @content;
    }
}

@mixin tablet-max {
    @media only screen and (max-width: $larger) {
        @content;
    }
}

@mixin tablet-res-height {
    @media only screen and (max-width: $larger) and (max-height: $small-s) {
        @content;
    }
}

@mixin tablet-large {
    @media only screen and(min-width : $large) {
        @content;
    }
}

@mixin tablet-large-max {
    @media only screen and(max-width : $large) and (min-height: $small) {
        @content;
    }
}

@mixin desktop-extra-s {
    @media only screen and(min-width : $larger) and (max-width: $extra) {
        @content;
    }
}

@mixin desktop-extra {
    @media only screen and(min-width : $extra) {
        @content;
    }
}

@mixin desktop-extra-max {
    @media only screen and(max-width : $extra) {
        @content;
    }
}

@mixin desktop-extra-middle {
    @media only screen and(max-width : $extra-middle) {
        @content;
    }
}

@mixin desktop-extra-large {
    @media only screen and(min-width : $extra-large) {
        @content;
    }
}

@mixin desktop-supper-middle {
    @media only screen and(min-width : $supper-middle) {
        @content;
    }
}

@mixin desktop-supper {
    @media only screen and(min-width : $supper-large) {
        @content;
    }
}

@mixin ipad-pro {
    @media (min-width: $larger) and (min-height: 1366px) {
        @content;
    }
}

@mixin between-1025-to-1440 {
    @media (min-width: 1025px) and (max-width: 1441px) {
        @content;
    }
}

@mixin screen-360 {
    @media (max-width: 360px) {
        @content;
    }
}

@mixin screen-2560 {
    @media only screen and(min-width : 2560px) {
        @content;
    }
}

@mixin screen-ipad-pro {
    @media only screen and (max-width: $screen-ipad-pro) {
        @content;
    }
}

@mixin screen-tv {
    @media only screen and (min-width: $screen-tv) and (min-height: 1500px) {
        @content;
    }
}
