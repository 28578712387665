.content-right__carousel-frames {
    padding: 46px 36px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;

    .frames-view {
        height: 100%;
        max-width: 100%;
        width: fit-content;
        margin: auto;
        position: relative;
        @include tablet-max {
            display: flex;
            justify-content: center;
        }

        .note {
            position: absolute;
        }

        .frames {
            &__note {
                font-family: "Lausanne";
                .dots {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 6px;
                    width: 6px;
                    border-radius: 100%;
                    background-color: #243670;
                    transform: translate(-50%, -50%);
                }

                &--label {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 0;
                    font-family: "Lausanne";
                    color: #243670;
                    font-size: 10px;
                    max-width: 100px;
                    min-width: 100px;
                    line-height: 1.2;

                    @include mobile {
                        font-size: 8px;
                    }
                }

                &--line {
                    position: absolute;
                    left: 0;
                    width: 1px;
                    background-color: #243670;
                }
            }
        }
    }
}
