$desktop-width: 1440px;
$tablet-width: 1280px;
$tablet_s-width: 1024px;

@mixin desktop {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}

@mixin tablet_S {
    @media only screen and (max-width: $tablet_s-width) {
        @content;
    }
}

.section-footer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    transition: 0.5s;
    position: fixed;
    z-index: 99;
    background-color: #fff;
    min-height: $height-menu-responsive;

    @include screen-ipad-pro {
        display: none;
    }

    @include desktop-extra-large {
        min-height: $height-menu;
    }
    &__middle {
        position: absolute;
        font-size: 40px;
        color: #f04e26;
        left: 50%;
        top: 77px;
        transform: translate(-50%, -50%);
        @include desktop-extra-middle {
            top: 54px;
        }
    }
    &__logo {
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
        height: 33px;
        @include desktop-extra-middle {
            height: 28px;
            top: 10px;
        }
    }
    &__nav-left,
    &__nav-right {
        width: calc(50% - 200px);
        display: flex;
        flex-wrap: wrap;
        li {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;
            margin: auto 0;
            font-weight: 400;
            font-size: 40px;

            @include tablet {
                font-size: 38px;
            }

            @include tablet_S {
                font-size: 27px;
            }

            a {
                color: #f04e26;
            }
        }
    }
    &__nav-right {
        margin-left: 400px;
    }
    ul {
        margin-bottom: 0;
    }
}
.list-active {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #f04e26;

        @include mobile {
            height: 1px;
            bottom: -2px;
            left: -5%;
            width: 110%;
        }
    }
    &:hover::after {
        background-color: #f04e26;
    }
}
.mobile-footer {
    height: 52px;
    position: relative;
    background: #ffffff;
    z-index: 1000;
    transition: all 0.3s linear;

    &__control {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;

        .control__icon {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 35px;
        }
    }

    &__list {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        background: #ffffff;

        a {
            font-family: "Cucina";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
            color: #f04e26;
        }
    }

    &__item {
        width: 100%;
        padding: 12px;
    }

    display: none;
    @include screen-ipad-pro {
        display: block;
    }
}

.mobile-footer--showList {
    transform: translateY(-230px);
}
