.modal-main {
    width: 50% !important;
    padding-bottom: $height-menu-responsive !important;
    @include desktop-extra-large {
        padding-bottom: $height-menu !important;
    }
    @include tablet-max {
        width: 70% !important;
    }
    @include mobile-large {
        width: 90% !important;
    }
    .ant-modal-content {
        background: #fff;
        border: 1.5px solid #5e0f16;
        border-radius: 20px;
        padding: 30px 50px;
        overflow: auto;
        max-height: calc(100vh - calc(#{$height-menu-responsive} + 20px));
        @include desktop-extra-large {
            max-height: calc(100vh - calc(#{$height-menu} + 20px));
        }
        @include tablet-max {
            padding: 0;
        }
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    .ant-modal-body {
        padding: 0;
    }
}
.modal-enquire {
    &__content {
        padding: 30px 60px;
        @include mobile-large {
            padding: 15px 10px;
        }
    }
    &__title {
        font-family: Lausanne;
        font-size: 18px;
        line-height: 32px;
        color: #5e0f16;
        text-transform: uppercase;
        text-align: center;
    }
    &__title-main {
        font-family: Lausanne;
        font-size: 56px;
        line-height: 44px;
        text-transform: uppercase;
        color: #5e0f16;
        text-align: center;
        margin-bottom: 15px;
    }
    &__desc {
        font-family: Cucina;
        font-size: 18px;
        line-height: 32px;
        text-transform: uppercase;
        color: #5e0f16;
        text-align: center;
        margin-bottom: 20px;
    }
    .btn-send {
        background: #5e0f16;
        border-radius: 10px;
        height: 48px;
        font-family: Lausanne;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        padding: 0 40px;
        color: #ffffff;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;
    }

    .errorMessage {
        font-size: 16px;
        line-height: 32px;
        color: #ed4d32;
    }
    .successMessage {
        text-align: center;
        font-size: 16px;
        line-height: 32px;
        color: #28a745;
    }
}
// input
.main-input {
    position: relative;
    margin-bottom: 20px;
    .content {
        position: relative;
    }
    label {
        font-family: Cucina;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #5e0f16;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        pointer-events: none;
        user-select: none;
        transition: 0.5s;
    }
    input {
        width: 100%;
        height: 48px;
        outline: none;
        border: 1px solid #5e0f16;
        border-radius: 10px;
        font-family: Cucina;
        font-size: 14px;
        line-height: 20px;
        color: #5e0f16;
        padding: 0 20px;
        &:focus + label {
            background: #fff;
            padding: 0 5px;
            top: 0;
            left: 15px;
        }
        &:not(:placeholder-shown) + label {
            background: #fff;
            padding: 0 5px;
            top: 0;
            left: 15px;
        }
    }
    span {
        color: red;
        font-family: Cucina;
        font-size: 12px;
    }
}
// textarea
.main-textarea {
    label {
        font-family: Lausanne;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #5e0f16;
        display: block;
        margin-bottom: 7px;
    }
    textarea {
        outline: none;
        padding: 15px;
        font-family: Lausanne;
        font-size: 12px;
        line-height: 16px;
        color: #5e0f16;
        border: 1px solid #5e0f16;
        height: 120px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 20px;
    }
    span {
        color: red;
        font-family: Cucina;
        font-size: 12px;
    }
}
// select
.main-select {
    margin-bottom: 20px;
    select {
        outline: none;
        border: none;
        border-bottom: 1px solid #5e0f16;
        width: 100%;
        font-family: Lausanne;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #5e0f16;
        padding: 6px 0;
        background: #ffffff;
    }
    span {
        color: red;
        font-family: Cucina;
        font-size: 12px;
    }
}
// area-code-phone
.area-code-phone {
    border: 1px solid #5e0f16;
    border-radius: 10px;
    height: 48px;
    margin-right: 20px;
    width: 48px;
    font-family: Cucina;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b6b8dd;
}
// row-model
.row-model {
    display: flex;
    .area-code-phone {
        flex-shrink: 0;
    }
    .main-input {
        flex: 1;
    }
}
