.detail-product {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #ecf8fe;
    overflow-x: hidden;

    @include mobile {
        padding-left: 16px;
        padding-right: 16px;
    }

    .col-sm {
        height: 100%;

        @include mobile {
            height: auto;
        }
    }

    .col-sm--left {
        @include screen-tv {
            min-height: 100%;
        }

        @media only screen and (min-height: 1500px) {
            max-height: 760px;
        }
    }

    &__back {
        padding-top: 35px;
        position: relative;

        @include mobile {
            padding-top: 28px;
        }

        .detail-product__router {
            background-color: #fff;
            cursor: pointer;
            padding: 0 10px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            border: none;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

            span {
                color: #f04e26;
                font-size: 18px;
                font-family: Lausanne;

                @include mobile {
                    font-size: 17px;
                }
            }
            @include mobile {
                font-size: 15px;
                height: 34px;
            }

            img {
                margin-right: 10px;
            }
        }

        .detail-product__router-right {
            position: absolute;
            top: -40px;
            right: 60px;

            @include mobile-large {
                display: none;
            }
        }

        .detail-product__router-mobile {
            display: none;

            @include mobile-large {
                margin-bottom: 20px;
                margin-top: -28px;
                display: block;
            }
        }
    }

    .ant-tabs {
        height: 100%;

        &-content {
            height: 100%;
            .ant-tabs-tabpane {
                @include mobile-large {
                    max-height: 200px;
                }
            }
        }
    }

    .content-right__carousel {
        height: 100%;

        .ant-carousel,
        .carousel-img {
            height: 100% !important;
        }

        .slick {
            &-slider,
            &-list,
            &-track,
            &-active {
                height: 100% !important;
            }

            &-slide {
                & > div {
                    height: 100%;
                }

                img {
                    height: 100%;
                    @include tablet-max {
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    .ant-row {
        height: 100%;
    }
    &__content {
        width: 100%;
        margin-top: 35px;
        height: 100%;

        .content-left {
            background-color: #ffffff;
            padding: 30px 15px;
            text-align: center;
            color: #243670;
            border-radius: 45px;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;

            @include screen-tv {
                padding: 100px 15px;
            }

            @include mobile {
                border-radius: 10px;
                padding: 32px 15px;
            }

            &__scroll {
                flex-grow: 1;
                height: calc(100% - 65px);
            }

            h4 {
                font-size: 42px;
                line-height: 44px;
                color: #243670;
                margin-bottom: 10px;

                @include desktop-extra-middle {
                    font-size: 32px;
                }

                @include desktop-extra-s {
                    font-size: 42px;
                }
                @include mobile {
                    font-size: 32px;
                }
            }
            p {
                font-size: 14px;
                line-height: 25px;
            }
            &__level {
                font-size: 18px !important;
            }
            &__rooms {
                width: 90%;
                margin: 0 auto;
                position: relative;
            }
            &__wrap-rooms {
                width: 50%;

                @include mobile {
                    min-width: 70%;
                }

                margin: 0 auto;
                font-size: 14px;
                line-height: 16px;
                &__line {
                    position: relative;
                    &:before,
                    &:after {
                        background-color: #f04e26;
                        content: "";
                        display: inline-block;
                        height: 2px;
                        position: relative;
                        vertical-align: middle;
                        width: 50%;
                    }
                    &:before {
                        right: 0.5em;
                        margin-left: -50%;
                    }

                    &:after {
                        left: 0.5em;
                        margin-right: -50%;
                    }
                }
            }
            &__cost {
                width: 65%;
                margin: 30px auto;
                .key-plan__title {
                    font-size: 18px;
                    padding-top: 25px;
                }
                .key-plan__desc {
                    font-size: 10px;
                    color: #d7dbce;
                    font-family: Lausanne;
                    margin: 0;
                }
                img {
                    width: 100%;
                }
                @include scroll-custom;

                @include desktop-extra {
                    height: calc(100% - 175px);
                    overflow: auto;
                }
            }
            &__wrapper {
                width: 90%;

                @include tablet-max {
                    width: 100%;
                }

                @include desktop-extra-s {
                    width: 100%;
                }
            }
            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__aspect {
                    font-family: Lausanne;
                }
                &__area {
                    position: relative;
                    font-family: Lausanne;
                }
            }
            &__item:not(:first-child) {
                margin-top: -20px;
            }
            .total__area {
                border-top: 1px solid #243670;
                padding-top: 5px;
                margin-top: -15px;
            }
            .total__price {
                border-bottom: 1px solid #243670;
                padding-top: 15px;
                p {
                    font-size: 18px !important;
                }
            }
            &__total {
                font-size: 18px;
            }
            .key-plan {
                width: calc(100% - 70px);
                margin: 0 auto;
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, 0);

                @include mobile {
                    bottom: 16px;
                }

                &__button {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    .key-plan__btn {
                        padding: 5px 15px;
                        font-size: 16px;
                        font-weight: bold;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        border-radius: 10px;
                        background-color: white;
                        border: 1px solid #243670;
                        transition: 0.5s;

                        @include mobile {
                            padding: 2px 15px;
                        }

                        img {
                            margin-left: 5px;
                        }

                        svg {
                            margin-left: 5px;
                            transition: 0.5s;
                            & > path {
                                fill: #243670;
                                transition: 0.5s;
                            }
                        }
                        &:hover {
                            color: #ffffff;
                            background: #243670;

                            img {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                    .btn-favorite {
                        margin-left: 20px;
                        width: 100%;
                    }
                    .key-plan__btn-center {
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &__top {
                position: absolute;
                top: 0;
                left: 32%;
                transform: translate(-50%, -50%);
                background-color: #57d9a3;
                width: 101px;
                height: 23px;
                border-radius: 10px;
                font-family: Lausanne;
                text-align: center;
                cursor: default;
                p {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-family: Lausanne;
                }
            }
            &__no-master {
                left: 50%;
            }
            &__Sold {
                background-color: rgb(240, 78, 38);
            }
            &__Reserved {
                background-color: rgb(245, 242, 162);
            }
            &__Available {
                background-color: #57d9a3;
            }
            &__status {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 0;
                left: 68%;
                transform: translate(-50%, -50%);
                background-color: #ffffff;
                border: 1px solid #25bcc5;
                width: 101px;
                height: 23px;
                border-radius: 10px;
                font-family: Lausanne;
                text-align: center;
                cursor: pointer;
                p {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-family: Lausanne;
                    color: #000000;
                }
                img {
                    width: 14px;
                    margin-top: 2px;
                    margin-right: 4px;
                }
            }
            &__bottom {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                background-color: #f5f2a2;
                width: 183px;
                height: 40px;
                border-radius: 20px;
                font-family: Lausanne;
                font-style: italic;
                font-size: 18px;
                text-align: center;
                cursor: pointer;
                p {
                    padding-top: 7px;
                }
            }
        }
        .content-right {
            background-color: #ffffff;
            padding: 20px 30px 10px;
            color: #243670;
            border-radius: 30px;
            height: 100%;
            position: relative;

            @include desktop-extra-s {
                min-height: 600px;
            }

            @include tablet-max {
                min-height: 585px;
            }

            @include mobile {
                border-radius: 10px;
                padding: 20px 10px 20px;
                min-height: 320px;
            }

            .text-drag {
                font-family: Cucina;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                color: #243670;
            }
            @include desktop-extra-large {
                padding: 30px 50px 10px;
                border-radius: 45px;
            }
            p {
                margin-bottom: 0 !important;
            }
            .ant-tabs {
                height: 100%;
            }

            .ant-tabs-nav-list {
                width: calc(100% - 5px);
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 905px) {
                    width: auto;
                }
            }

            .ant-tabs-nav::before {
                border-bottom: 1px solid #f04e26;
            }
            .ant-tabs-tab-btn {
                color: #243670;
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #243670;
            }
            .ant-tabs-ink-bar {
                background: #f04e26;
            }
            .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
                height: 5px;
            }
            .ant-tabs-tab {
                font-size: 18px;
            }

            @include mobile {
                .ant-tabs-content-holder {
                    height: 176px;
                }
            }

            &__img {
                display: flex;
                justify-content: center;
                height: 90%;
                position: relative;
                @include tablet-max {
                    height: 90%;
                    width: 100%;
                }
                @include mobile-large {
                    height: auto;
                }
                .pdf-img {
                    width: 100%;
                    object-fit: contain;
                }
                .block-show-pdf {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-family: Lausanne;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 10px;
                    color: #243670;
                    img {
                        margin-left: 15px;
                    }
                }
            }
            &__desc {
                width: 40%;
                position: absolute;
                bottom: 31px;
                right: 20px;
                p {
                    font-family: Lausanne;
                    font-size: 8px;
                    color: #243670;
                    line-height: 10px;
                }

                @include tablet-max {
                    width: 90%;
                    bottom: 10px;
                    text-align: center;
                }

                @include mobile {
                    width: 90%;
                    bottom: 10px;
                    text-align: center;
                }
            }
            &__download {
                padding: 5px 15px;
                background-color: #243670;
                font-size: 16px;
                border-radius: 5px;
                position: absolute;
                bottom: 66px;
                left: 50%;
                transform: translateX(-50%);
                color: #ffffff;
                font-weight: 700;
                img {
                    margin-left: 5px;
                }
                @include desktop-extra-middle {
                    left: 50%;
                    bottom: 31px;
                }
                @include mobile-large {
                    padding: 3px 10px;
                    font-size: 12px;
                    bottom: 30px;
                    left: 50%;
                }
            }
            &__carousel {
                position: relative;
                .carousel-img {
                    display: flex !important;
                    justify-content: center;
                    height: 60vh;

                    &__wrap {
                        width: 100%;
                        overflow: auto;

                        @include tablet-max {
                            display: flex;
                        }
                        img {
                            width: auto;
                            max-width: 650px;
                            max-height: 650px;
                            margin: auto;
                            height: 100%;
                            background-size: contain;
                            background-repeat: no-repeat;

                            @include tablet-max {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .carousel-btn {
                    position: static;
                    bottom: 10px;
                    width: 300px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        text-align: center;
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #ffffff;
                        border: none;
                        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
                            0 6px 6px rgba(0, 0, 0, 0.23);
                        svg {
                            margin-top: 6px;
                        }
                    }

                    .prev {
                        position: absolute;
                        top: calc(50% - 20px);
                        left: 20px;
                    }

                    .next {
                        position: absolute;
                        top: calc(50% - 20px);
                        right: 20px;
                    }
                }
            }
            .indicative-img {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                height: 100%;
                margin-top: -8px;
                img {
                    width: 100%;
                }
            }
            .colour-img {
                display: flex;
                justify-content: center;
                height: 60vh;

                img {
                    width: 100%;
                    height: 100%;
                    @media screen and (max-width: 1366px) {
                        width: 75%;
                    }
                }
            }
            .ant-tabs-content-holder {
                height: 100%;
            }
        }
    }

    &.detail-product__v2 {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @include tablet-max {
            overflow-y: auto;
        }

        @include desktop-extra-s {
            overflow-y: auto;
        }

        .detail-product__content {
            margin: 0;
            flex-grow: 1;
            padding-bottom: 50px;
            padding-top: 35px;
            height: calc(100% - 75px);

            @include mobile {
                padding-top: 30px;
            }

            @include tablet-max {
                .ant-row {
                    .ant-col {
                        padding-bottom: 40px;
                        height: auto;
                    }
                }
            }

            @include desktop-extra-s {
                .ant-row {
                    .ant-col {
                        padding-bottom: 40px;
                        height: auto;
                    }
                }
            }
        }
    }
}
.modal-content {
    &__btn {
        width: 250px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .key-plan__btn {
            border: 1px solid #004f39;
            border-radius: 10px;
            cursor: pointer;
            background-color: inherit;
        }
        .key-plan__btn-center {
            margin: 0 auto;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #004f39;
            padding: 5px;
            svg:first-child {
                margin-left: 5px;
                margin-right: 5px;
            }
            img {
                margin-left: 5px;
            }
        }
    }
}
.modal__finishes {
    width: fit-content !important;
    padding-bottom: $height-menu-responsive !important;
    @include desktop-extra-large {
        padding-bottom: $height-menu !important;
    }
    .ant-modal-content {
        border-radius: 25px;
        background-color: #b3e4fa;
        padding: 30px 50px 22px;
        max-height: calc(100vh - calc(#{$height-menu-responsive} + 20px));
        @include desktop-extra-large {
            max-height: calc(100vh - calc(#{$height-menu} + 20px));
        }
        @include mobile-large {
            padding: 50px 48px;
        }
    }
    .ant-modal-close {
        right: -3px !important;
        top: -3px !important;
    }
    .ant-modal-body {
        padding: 0;
        .modal-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &__btn {
                margin-top: 10px;
                justify-content: center;
                .btn-favorite {
                    margin-left: 25px;
                }
            }
        }
        img {
            cursor: pointer;
            max-height: calc(100vh - calc(#{$height-menu-responsive} + 120px));
            @include desktop-extra-large {
                max-height: calc(100vh - calc(#{$height-menu} + 120px));
            }
            @include mobile-large {
                max-height: calc(100vh - calc(#{$height-menu} + 220px));
            }
        }
    }
}
.colour-popup {
    width: 146px;
    height: 111px;
    margin-top: 16px;
    border: 2px solid #f5f2a2;
    border-radius: 6px;
    font-size: 12px;
    color: #243670;
    padding: 20px 20px;
    text-align: center;
    background-color: #ffffff;
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
    }
    p:first-child {
        border-bottom: 1px solid #f5f2a2;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    p {
        cursor: pointer;
        margin-bottom: 14px !important;
    }
}

.modal__status {
    width: 60% !important;
    padding-bottom: $height-menu-responsive !important;
    @include desktop-extra-large {
        padding-bottom: $height-menu !important;
    }
    @include tablet-max {
        width: 90% !important;
    }
    &--form {
        @include mobile-large {
            padding: 0 0 0 5px;
        }
    }
    .ant-modal-content {
        border-radius: 25px;
        border: 1px solid #5e0f16;
        padding: 64px 45px 64px 64px;
        max-height: calc(100vh - calc(#{$height-menu-responsive} + 20px));
        @include desktop-extra-large {
            max-height: calc(100vh - calc(#{$height-menu} + 20px));
        }
        @include mobile-large {
            padding: 15px 5px;
        }
    }
    .ant-modal-close {
        right: -3px !important;
        top: -3px !important;
    }
    .ant-modal-body {
        text-align: center;
        padding: 0;
        overflow-y: auto;
        @include scroll-custom;
        @include desktop-extra-middle {
            max-height: calc(100vh - 180px);
        }
    }
    &__desc {
        font-size: 18px;
        font-family: Lausanne;
        color: #5e0f16;
        text-transform: uppercase;
    }
    &__title {
        font-size: 56px;
        color: #5e0f16;
        @include mobile-large {
            font-size: 35px;
        }
    }
    &__items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .status__error {
            color: red;
        }
        .modal__status__select {
            width: 100%;
            margin-left: 30px;
            margin-right: 20px;
            .ant-picker {
                width: 100%;
                margin-bottom: 16px;
                border-radius: 8px;
                input {
                    padding: 5px 0;
                    cursor: pointer;
                    color: #5e0f16;
                }
                .ant-picker-suffix {
                    color: #5e0f16;
                }
            }
        }
        label {
            font-size: 16px;
            color: #5e0f16;
            width: 10%;
            @include mobile-large {
                width: 25%;
            }
        }
        textarea {
            width: 100%;
            margin-left: 30px;
            border-radius: 10px;
            padding-top: 5px;
            padding-left: 13px;
            outline: none;
            margin-right: 20px;
            &:focus {
                border: 1px solid #5e0f16;
            }
        }
    }
    &__btn {
        display: flex;
        margin-left: calc(10% + 20px);
        margin-top: 64px;
        @include mobile-large {
            margin-top: 16px;
        }
        @include desktop-extra {
            margin-top: 16px;
        }
        @include desktop-extra-large {
            margin-top: 64px;
        }
        .btn__save {
            margin-left: 24px;
            background-color: #5e0f16;
            color: #ffffff;
            border: none;
        }
        .btn__cancel {
            color: #5e0f16;
            border: 1px solid #5e0f16;
            background-color: #ffffff;
        }
        .btn__cancel,
        .btn__save {
            padding: 5px 25px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}

.form-status__email {
    font-size: 14px;
    color: #5e0f16;
    padding: 10px 10px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid hsl(0, 0%, 80%);
    outline: none;
    &:focus {
        border: 1px solid #5e0f16;
    }
}
.form-status__error {
    color: red;
}
