.account-layout {
    &__logo {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;
        svg {
            display: block;
            margin: 0 auto;
        }
        @include tablet-max {
            display: none;
        }
    }
    .ant-layout-sider {
        width: 262px !important;
        max-width: 262px !important;
        flex: 0 0 262px !important;
    }
    .ant-menu-item {
        margin-top: 28px !important;
        display: flex;
        align-items: center;
        cursor: pointer;
        a {
            color: #979797;
            font-family: Lausanne;
            font-size: 14px;
            &:hover {
                color: #979797;
            }
        }
        p {
            @include tablet-max {
                display: none;
            }
        }

        @include desktop-extra {
            min-width: 120px;
            max-width: 140px;
            @include desktop-extra {
                min-width: 135px;
            }
        }
    }
    .ant-menu-item-icon {
        @include desktop-extra {
            min-width: 20px;
        }
        @include mobile-large {
            min-width: 14px;
        }
    }
    .ant-menu-item:last-child {
        margin-top: 58px !important;
    }
    .ant-menu::before {
        position: absolute;
        content: "";
        bottom: 65px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
    }
    .ant-menu-item {
        @include tablet-max {
            padding: 0 34px;
        }
        @include tablet-mini {
            padding: 0 25px;
        }
        @include mobile-large {
            padding: 0 9px;
        }
    }
    .ant-menu-item-selected {
        img {
            filter: brightness(0) invert(1);
        }

        a {
            color: #ffffff;
            &:hover {
                color: #ffffff;
            }
        }
        background-color: #f1674c !important;
        border-radius: 8px;
    }
    &__header {
        background-color: #ffffff;
        &__wrap {
            width: 100%;
            padding: 35px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: Lausanne;
            @include mobile-large {
                padding: 15px 20px;
            }
            h4 {
                color: #3c3c3c;
                font-size: 28px;
                font-family: Lausanne;
                @include mobile-large {
                    font-size: 20px;
                }
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .account-layout__header__right {
                cursor: pointer;
                .account-layout__header__back {
                    flex-shrink: 0;
                    width: 41px;
                    height: 41px;
                    background-color: #ed4d32;
                    border-radius: 50%;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                p {
                    font-size: 16px;
                    color: #666279;
                    font-family: "Lausanne";
                    @include mobile-large {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    p {
        margin-bottom: 0;
    }
}
