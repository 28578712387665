.popup-share-doc-wrapper {
    .form-enquire {
        .list-file-share {
            padding-left: 10px;
            padding-right: 10px;
            flex-direction: column;
            margin-bottom: 20px;
            .file-share {
                display: flex;
                justify-content: space-between;
                border-radius: 8px;
                border: none;
                background: rgba(94, 15, 22, 0.12);
                margin-bottom: 10px;
                padding: 10px 20px;

                span {
                    color: #5e0f16;
                    font-family: Lausanne;
                }
                button {
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }
        }
        .btn-submit {
            display: flex;
            justify-content: center;
            .btn-share {
                color: #ffffff;
                padding: 10px;
                border-radius: 16px;
                width: 80%;
                background-color: #5e0f16;
                font-family: Lausanne;
            }
        }
    }

    .form-pdf {
        display: flex;
    }
}

.schedule-modal {
    padding: 24px;
    .ant-modal-content {
        background-color: #b3e4fa;
        .ant-modal-body {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    .img-wrapper {
        display: flex;
        justify-content: center;
        .img {
            padding: 20px;
            max-width: 400px;
            max-height: 500px;
        }
    }
    .btn-group {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 24px;
        &--btn-center {
            gap: 10px;
            border: 1px solid #004f39;
            background-color: transparent;
            border-radius: 8px;
            padding: 8px 16px;
            span {
                margin-right: 10px;
            }
        }
    }
}

.modal-pdf {
    .ant-modal-content {
        padding: 0;
        margin: 0;
        .ant-modal-body > div {
            padding: 0;
            margin: 0;
            .item-name {
                font-family: Cucina;
                font-size: 56px;
                text-align: center;
                margin-top: -30px;
                color: #5e0f16;
            }
            .item-position {
                font-family: Lausanne;
                font-size: 18px;
                line-height: 32px;
                color: #5e0f16;
                text-transform: uppercase;
                text-align: center;
                margin-top: -10px;
                margin-bottom: 24px;
            }
            .form-pdf {
                .btn-submit {
                    margin-top: 24px;
                    display: flex;
                    justify-content: center;
                    .btn-share {
                        color: #ffffff;
                        padding: 10px;
                        border-radius: 16px;
                        width: 80%;
                        background-color: #5e0f16;
                        font-family: Lausanne;
                    }
                }
            }
        }
    }
}
