.loading-spinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    svg {
        display: block;
        margin: 0 auto;
        fill: #00a0ff;
        circle {
            animation-name: loading-spinner;
            animation-duration: 2s;
            animation-timing-function: cubic-bezier(0.05, 0.2, 0.35, 1);
            animation-iteration-count: infinite;
            &:nth-child(2) {
                animation-delay: 0.18s;
            }
            &:nth-child(3) {
                animation-delay: 0.36s;
            }
        }
    }
    @keyframes loading-spinner {
        0% {
            opacity: 0;
            transform: translateY(0);
        }
        25% {
            opacity: 1;
            transform: translateY(-10px);
        }
        75% {
            opacity: 1;
            transform: translateY(-10px);
        }
        100% {
            opacity: 0;
            transform: translateY(0);
        }
    }
}
