.container {
    height: 100vh;
    overflow-y: hidden;
    @include screen-ipad-pro {
        height: calc(var(--vh, 1vh) * 100);
    }
    .content {
        height: calc(100vh - #{$height-menu-responsive});
        z-index: 1;
        @include desktop-extra-large {
            height: calc(100vh - #{$height-menu});
        }
        @include screen-ipad-pro {
            height: calc(calc(var(--vh, 1vh) * 100) - #{$height-mobile-menu});
        }
    }
    .footer {
        height: $height-menu-responsive;
        height: 80px;
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 9999;
        @include desktop-extra-large {
            height: $height-menu;
        }
    }
}
