@mixin iphone667 {
    @media only screen and (max-width: 667px) {
        @content;
    }
}

@mixin ip7 {
    @media only screen and (max-width: 900px) {
        @content;
    }
}

.availability-title {
    position: fixed;
    top: calc(50% - 80px);
    transform: translateY(calc(-1px * calc(50% - 80px)));
    right: -190px;

    @include tablet-max {
        right: -180px;
    }
    @include mobile-large {
        right: -215px;
    }
    z-index: 10;
    transition: -0.5s;

    @include tablet-res-height {
        transform: translateY(calc(-1px * calc(50% - 70px)));
        z-index: 5;
    }
    button {
        background: #b3e4fa;
        border: 1px solid #004f39;
        transform: rotate(90deg);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 20px;
        font-family: Cucina;
        font-size: 25px;
        line-height: 32px;
        color: #004f39;
        border-top: 0;
        span {
            @include mobile-large {
                visibility: hidden;
            }
        }
        @include desktop-extra-large {
            padding: 20px 20px 30px;
            font-size: 28px;
        }
        @include tablet-res-height {
            padding: 14px;
            font-size: 22px;
            margin-top: 20px;
            margin-right: 20px;
        }
        img {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%) rotate(270deg);
            width: 40px;
            height: 40px;
            margin-right: 20px;
            cursor: pointer;

            border: 1px solid #004f39;
            padding: 6px;
            background: #004f39;
            border-radius: 50px;
        }
    }
}
.availability {
    position: absolute;
    top: 0;
    left: 100%;
    width: 94%;
    margin-top: 4vh;
    margin-left: 3%;
    z-index: 100;
    background: #b3e4fa;
    border: 1.5px solid #004f39;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 50px 30px 50px 60px;
    transition: 0.5s;
    height: calc(92vh - #{$height-menu-responsive});
    @include desktop-extra-large {
        height: calc(92vh - #{$height-menu});
    }
    @include mobile-large {
        width: 90%;
        padding: 10px 5px 10px 20px;
    }
    &.active {
        left: 0;
    }
    .icon-close {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50px);
        right: -21px;

        border: 1px solid #004f39;
        padding: 12px;
        background: #b3e4fa;
        border-radius: 50px;
    }
    &__title {
        font-family: Cucina;
        font-size: 42px;
        line-height: 44px;
        color: #004f39;
        margin-bottom: 40px;
        @include mobile-large {
            font-size: 35px;
            margin-bottom: 0;
        }
    }
    &__line {
        position: relative;
        margin-bottom: 30px;
        margin-top: 20px;
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: #004f39;
            display: block;
            position: absolute;
            top: 10px;
            left: 0px;
        }
        span {
            position: relative;
            background: #b3e4fa;
            z-index: 1;
            padding-right: 20px;
        }
    }
    &__scroll {
        overflow: auto;
        height: calc(100% - 50px);
        padding-right: 30px;
        @include scroll-custom;
        @include mobile-large {
            height: calc(100% - 140px);
        }
    }
}
.availability-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    &__item {
        cursor: pointer;
        width: calc(12.5% - 40px);
        height: 200px;
        background-color: #d7dbce;
        height: 80px;
        margin-left: 40px;
        margin-bottom: 30px;

        &--disabled {
            cursor: no-drop;
            opacity: 0.5;
        }

        @include tablet-max {
            width: calc(12.5% - 25px);
            margin-left: 25px;
        }
        @include tablet-mini {
            width: calc(16.6% - 25px);
            margin-left: 25px;
        }

        @media only screen and (max-width: 992px) {
            width: calc(25% - 25px);
            margin-left: 25px;
        }

        @include ip7 {
            width: calc(16.6% - 25px);
            margin-left: 25px;
        }
        @include iphone667 {
            width: calc(20% - 25px);
            margin-left: 25px;
        }

        @include mobile-large {
            width: calc(33.3% - 15px);
            margin-left: 15px;
        }

        p {
            margin-bottom: 0;
            width: 100%;
            height: 60px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #243670;
            font-family: Cucina;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        span {
            text-align: center;
            width: 100%;
            height: 20px;
            font-family: Cucina;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #243670;
            background: #ef684d;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.23);

            @include ip7 {
                font-size: 10px;
            }
        }
    }
    &__AVAILABLE {
        background: rgb(179, 228, 250) !important;
    }
    &__SOLD {
        background: rgb(240, 78, 38) !important;
    }
    &__RESERVED {
        background: rgb(245, 242, 162) !important;
    }
}
