.list-apartment {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
    &__scroll {
        width: 100%;
        height: calc(100% - 115px);

        @include mobile {
            height: calc(100% - 90px);
        }

        overflow: auto;
        padding-right: 5px;
        @include scroll-custom;
    }
    &__title {
        font-family: Cucina;
        font-size: 42px;
        line-height: 44px;
        color: #b3e4fa;

        @include mobile {
            font-size: 32px;
            line-height: 40px;
        }
    }

    &__table {
        &--no-collection {
            height: calc(100% - 65px);
            overflow: auto;
            padding-right: 4px;
            @include scroll-custom;
        }
        @include mobile {
            width: 100%;
            @include scroll-hidden;
            &::-webkit-scrollbar {
                height: 0;
            }
        }

        .table-apartment > thead > tr > th {
            @include mobile {
                min-width: 67px;
            }
        }

        .table-apartment > thead > tr > th:nth-child(5) {
            @include mobile {
                min-width: 80px;
            }
        }
    }

    &__name-collection {
        font-family: Cucina;
        font-size: 18px;
        line-height: 32px;
        color: #b3e4fa;
        text-transform: uppercase;

        @include mobile {
            font-size: 16px;
            line-height: 25px;
        }
    }
    &__desc {
        font-family: Cucina;
        font-size: 18px;
        line-height: 32px;
        color: #b3e4fa;
        position: relative;
        .block-seemore {
            margin-bottom: 15px;
            font-family: Cucina;
            font-size: 18px;
            line-height: 32px;
            color: #b3e4fa;
            position: relative;
            font-size: 16px;
            line-height: 20px;
            .anchor-class {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: #b3e4fa;

                @include mobile {
                    font-size: 12px;
                }
            }
            span {
                @include mobile {
                    font-size: 12px;
                }
            }
            p {
                font-size: 16px;
                line-height: 20px;
                span:last-child {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    &:hover {
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }
                @include mobile {
                    font-size: 12px;
                }
            }

            ul {
                list-style: disc;
                margin-left: 20px;
                font-size: 16px;
                line-height: 20px;

                @include mobile {
                    font-size: 12px;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 20px;

            span:last-child {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;

                &:hover {
                    text-decoration-line: underline;
                    cursor: pointer;
                }
            }
        }

        ul {
            list-style: disc;
            margin-left: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        @include mobile {
            position: unset;

            .list-apartment__btn-back {
                position: absolute;
                top: -42px;
                left: 0;
            }
        }

        &-pagination {
            @include mobile {
                margin-left: 50px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}
// btn-back-main
.btn-back-main {
    cursor: pointer;
    font-family: Lausanne;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #b3e4fa;
    border: 1px solid #b3e4fa;
    border-radius: 10px;
    background-color: transparent;
    height: 34px;
    padding: 0 15px;
    flex-shrink: 0;
    img {
        margin-right: 10px;
    }

    @include mobile {
        font-size: 16px;
        position: absolute;
        top: -42px;
        left: 0;
    }
}
// table list apartment
.table-apartment {
    width: 100%;
    thead {
        font-size: 14px;
        line-height: 20px;
        color: #252235;
        .text-header {
            font-family: Lausanne;
            height: 50px;
            background: #b3e4fa;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
        }
        th {
            &:first-child {
                .text-header {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
            &:last-child {
                .text-header {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
    tbody {
        font-size: 14px;
        line-height: 20px;
        color: #252235;
        @include scroll-custom;
        .text-body {
            font-family: Lausanne;
            height: 50px;
            background: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
        }
        td {
            &:first-child {
                .text-body {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
            &:last-child {
                .text-body {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
}

.pagination-main {
    .ant-pagination-item {
        border: 2px solid #b3e4fa;
        width: 32px;
        height: 32px;
        background: #004f39;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        border-radius: 100%;

        @include mobile {
            height: 30px;
        }

        a {
            position: relative;
            top: 2px;
            color: #b3e4fa;
        }
    }
    .ant-pagination-prev {
        .ant-pagination-item-link {
            border: 2px solid #b3e4fa;
        }
    }
    .ant-pagination-next {
        .ant-pagination-item-link {
            border: 2px solid #b3e4fa;
        }
    }
    .ant-pagination-jump-next {
        pointer-events: none;
    }
    .ant-pagination-jump-prev {
        pointer-events: none;
    }
    .ant-pagination-item-after-jump-prev {
        display: none;
    }
    .ant-pagination-item-before-jump-next {
        display: none;
    }
    .ant-pagination-item-link {
        background: #004f39;
        border-radius: 100%;
        text-align: center;

        svg {
            color: #b3e4fa;
            position: relative;
            top: -2px;
        }
    }

    .ant-pagination-item-active {
        background-color: #b3e4fa;
        border-radius: 15px;
        height: 32px;
        min-width: 50px;
        line-height: 25px;
        cursor: pointer;
        align-items: center;

        @include mobile {
            height: 30px;
        }

        a {
            color: #004f39;
        }
    }

    @include mobile {
        .ant-pagination-prev {
            height: 30px;
        }

        .ant-pagination-next {
            height: 30px;
        }
    }
}
