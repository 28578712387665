@import "./common/breakpoints";
@import "./common/fonts";
@import "./common/vars";
@import "./common/mixin-common";
@import "./common/form";
@import "./common/input-field";
@import "./common/loading-spinner";
@import "./home/home";
@import "./login/login";
@import "./layout/layout";
@import "./layout/footer";
@import "./location/location";
@import "../styles/apartments/Filters-content/filters-content";
@import "./detail_product/detail_product";
@import "./apartments/apartments";
@import "./amenities/amenities";
@import "./common/account";
@import "./gallery/gallery";
@import "./list_apartment/index";
@import "./modal/index";
@import "./gallery/frames";
@import "./block_availability/index";
@import "./detail_product/frames";
@import "./admin_layout/admin_layout";
@import "./compare_image/index";
@import "./admin_documents/admin_documents";
@import "./compare_image/index";
@import "./show-pdf/index";
@import "./admin_my-details/admin_my-details";
@import "./form/inputField";
@import "./common/button";
@import "./admin_settings/admin_settings";
@import "./common/pointer";
@import "./admin_documents/popup-share-document";
@import "./common/select-field";
@import "./common/animation-apartment";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Cucina;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}
