.compare_image {
    height: 100%;
    border: 1px solid #fff;
    position: relative;
    height: calc(100% - 20px);
    width: 100%;
    margin: 0 auto;

    .list-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        &:not(:first-child) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .ant-slider {
            margin: 0;
            height: 100%;
            padding: 0;
            overflow: hidden;
            .ant-slider-rail {
                background-color: transparent;
                position: relative;
            }
            .ant-slider-track {
                background-color: transparent;
            }
            .ant-slider-handle {
                pointer-events: visible;
                position: absolute;
                top: calc(50% - 15px);
                cursor: e-resize;
                width: 30px;
                height: 30px;
                background-color: transparent;
                border: solid 2px #fff;
                z-index: 99;
                @include desktop-extra-large {
                    top: calc(50% - 25px);
                    cursor: e-resize;
                    width: 50px;
                    height: 50px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: -600px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 600px;
                    width: 2px;
                    background: #fff;
                    @media (min-height: 1200px) {
                        height: 1000px;
                        top: -1000px;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -600px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 600px;
                    width: 2px;
                    background: #fff;
                    @media (min-height: 1200px) {
                        height: 1000px;
                        bottom: -1000px;
                    }
                }
            }
            .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
                border: solid 2px #fff;
            }
            &:hover {
                .ant-slider-rail {
                    background-color: transparent;
                }
                .ant-slider-track {
                    background-color: transparent;
                }
                .ant-slider-handle:not(.ant-tooltip-open) {
                    border: solid 2px #fff;
                }
            }
        }
    }

    .ant-slider-handle-1 {
        top: 35% !important;
    }

    .ant-slider-handle-2 {
        top: 65% !important;
    }

    .image-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}
