.account-mydetails {
    padding: 10px 37px 67px 37px;
    overflow-x: hidden;
    @include mobile-large {
        padding: 5px 10px 30px;
    }

    &__form-update {
        background: #ffffff;
        padding: 14px 0 14px 52px;
        padding-right: 30%;
        border-radius: 24px;
        box-shadow: 0 3px 30px 0px rgba(0, 0, 0, 0.05);
        @include tablet-max {
            padding: 14px;
        }

        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            color: #ed4d32;
            margin-bottom: 16px;
        }

        &-btn {
            margin-top: 37px;
        }
        .account-mydetails__successful {
            color: #28a745;
        }
        .account-mydetails__failed {
            color: red;
        }
    }

    &__form-advance {
        padding: 32px 40px;
        background: #e4ebca;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 3px 30px 0px rgba(0, 0, 0, 0.05);

        h1 {
            font-family: "Proxima";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            color: #ed4d32;
            text-align: center;
        }

        .form-advance__list-link {
            margin: 50px 0px;
            height: auto;
            width: 100%;
            a {
                font-family: "Proxima";
                padding: 14px 20px;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                background-color: #a6be52;
                border-radius: 16px;
                margin-bottom: 20px;
                display: block;
            }
        }
    }

    &__sub-users {
        padding: 24px 0 24px 52px;
        padding-right: 40%;
        background: #ffffff;
        border-radius: 24px;
        box-shadow: 0 3px 30px 0px rgba(0, 0, 0, 0.05);
        @include tablet-max {
            padding: 14px;
        }

        h1 {
            font-family: "Proxima";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            color: #ed4d32;
        }

        .sub-users__group {
            width: 100%;
            margin-top: 30px;

            &__info-user {
                padding: 13px 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #e2e2ea;
                border-radius: 12px;
                max-height: 80px;
                margin-bottom: 6px;

                .info-user__wrap-content {
                    display: flex;
                    align-items: center;

                    .info-user__avata {
                        height: 54px;
                        width: 54px;
                        padding: 16px 11px;
                        background: #ffffff;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ed4d32;
                        font-style: normal;
                        font-family: "Proxima";
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        margin-right: 45px;
                    }

                    .info-user__content {
                        h2 {
                            font-family: "Proxima";
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                            color: #11142d;
                            margin: 0;
                        }

                        h3 {
                            font-family: "Proxima";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 20px;
                            color: #11142d;
                            margin: 0;
                        }
                    }
                }
            }

            &__form-invite {
                padding: 12px 34px 18px 18px;
                background: #f1f1f5;
                border-radius: 12px;

                label {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 32px;
                    color: #666279;
                    font-family: "Proxima";
                }

                .form-invite__input {
                    margin-top: 10px;
                    position: relative;
                    &-suggest {
                        padding: 8px 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: 155px;
                        border: 1px solid #e2e2ea;
                        border-radius: 16px;
                        background: #f1f1f5;

                        position: absolute;
                        top: -4%;
                        left: 25%;

                        span {
                            font-family: "Proxima";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #44444f;
                            margin-right: 20px;
                        }
                    }

                    &-field {
                        background: none;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid #e2e2ea;
                        width: 100%;
                        margin-bottom: 30px;
                        font-family: "Proxima";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #44444f;
                        height: 40px;
                    }
                }
            }
        }
    }
}
