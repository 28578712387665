.settings {
    padding: 10px 47px;
    overflow-x: hidden;
    @include mobile-large {
        padding: 10px 5px;
    }
    .settings__button {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    &__form-change-password {
        width: 100%;
        min-height: 250px;
        background: #ffffff;
        border-radius: 24px;
        box-shadow: 0 3px 30px 0px rgba(0, 0, 0, 0.05);
        padding: 24px 155px 24px 46px;
        @include mobile-large {
            padding: 24px 5px;
        }

        h1 {
            font-family: "Proxima";
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            text-transform: uppercase;
            color: #ed4d32;
            margin-bottom: 33px;
        }

        .form__inputs {
            display: flex;
            flex-direction: column;
        }

        .form__delete-account {
            border-top: 1px solid #e4e4e4;
            display: flex;
            align-items: baseline;
            h1 {
                margin-top: 77px;
                font-family: "Proxima";
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 32px;
                text-transform: uppercase;
                color: #ed4d32;
                margin-right: 20px;
            }
        }
        .notification__success {
            color: #28a745;
            padding-bottom: 20px;
        }
        .notification__failed {
            color: red;
            padding-bottom: 20px;
        }
    }

    &__inputField {
        font-family: "Proxima";
        display: flex;
        flex-direction: column;

        &-label {
            font-family: "Proxima";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #505f79;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        &-input {
            font-family: "Proxima";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #505f79;
            padding: 16px 23px;
            border-radius: 10px;
            border: 1px solid #e2e2ea;
            outline: none;
        }

        &-error {
            font-family: "Proxima";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #ef684d;
            margin-top: 4px;
        }
    }
}
