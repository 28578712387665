.location {
    width: 100%;
    height: 100%;
    position: relative;

    &__background {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: auto;
    }

    &__image {
        min-width: 100%;
        min-height: 100%;
    }

    &__viewbox {
        position: relative;
        height: 100%;
    }
    &__point {
        position: absolute;
        color: #fff;
        cursor: default;

        .name {
            padding: 5px 12px;
            border-radius: 15px;
            background-color: #243670;
            display: inline-block;
            position: absolute;
            top: -100px;
            min-width: 120px;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 12px;
            z-index: 5;
            text-align: center;
            img {
                width: 15px;
                margin-right: 5px;
            }
        }

        .l-arrow {
            height: 100px;
            width: 3px;
            background-color: #243670;
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);
            display: inline-block;
            z-index: 2;
        }

        .l-icon {
            width: 220px;
            transform: translate(-55%, -75%);
        }
    }

    &__point--main {
        cursor: pointer;
    }

    .menu {
        position: absolute;
        width: 315px;
        left: -285px;
        z-index: 20;
        background: #243670;
        opacity: 0.9;
        border: 1.5px solid #b6b8dd;
        box-sizing: border-box;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: all 0.3s linear;
        top: 0;
        bottom: 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;

        @include mobile-large() {
            width: 345px;
            left: -320px;
        }

        @media (max-height: 600px) {
            height: 90%;
        }

        &__icon {
            position: absolute;
            top: 45%;
            right: -5%;
            width: 41px;
            height: 41px;
            background: #b6b8dd;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            cursor: pointer;
            outline: none;
            transition: 0.5s;

            &:hover {
                background: #7b7eb1;
            }
        }

        &__icon--active {
            img {
                transform: rotate(180deg);
            }
        }

        &__list {
            padding: 10px 35px;
            margin-bottom: 0;
            overflow: auto;

            @include scroll-custom {
                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                    margin-block-start: 30px;
                    margin-block-end: 30px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
            }
        }

        &__list-item {
            padding: 10px 0 10px 25px;
            img {
                margin-right: 10px;
            }
            a {
                font-weight: normal;
                font-size: 16px;
                line-height: 32px;
                color: #b6b8dd;
                text-decoration: none;

                @include mobile-large() {
                    font-size: 15px;
                }
            }
        }

        &__list-item:not(:last-child) {
            border-bottom: 1.5px solid #b6b8dd;
        }
    }

    .menu--open {
        left: -1.5%;
    }
}
