$desktop-width: 1440px;
$tablet_l-width: 1280px;
$tablet_s-width: 1024px;
$tv_m-width: 2560px;
$tv_xl-width: 3895px;
$tv_xxl-width: 3896px;

@mixin desktop {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin tablet-l {
    @media only screen and (max-width: $tablet_l-width) {
        @content;
    }
}

@mixin tablet-s {
    @media only screen and (max-width: $tablet_s-width) {
        @content;
    }
}

@mixin tv-m {
    @media only screen and (min-width: #{$tv_m-width}) and (max-width: #{$tv_xl-width}) {
        @content;
    }
}

@mixin tv-l {
    @media only screen and (min-width: $tv_xxl-width) {
        @content;
    }
}

.frames {
    width: 100%;
    height: calc(100vh - #{$height-menu-responsive});
    position: relative;

    @include mobile-large {
        height: calc(100vh - #{$height-mobile-menu} - #{$height-mobile-menu}) !important;
        overflow-x: auto;
        @include scroll-hidden;
        padding: 90px 50px !important;
    }
    @include tablet-max {
        overflow-x: auto;
        @include scroll-hidden;
    }
    @include screen-ipad-pro {
        height: calc(100vh - #{$height-mobile-menu});
    }
    @include desktop-extra-large {
        height: calc(100vh - #{$height-menu});
    }

    background-color: #b3e4fa;
    padding: 90px 100px;

    @include tablet-s {
        padding: 90px 100px;
    }

    &__note {
        position: absolute;
        z-index: 1;
        max-width: 184px;
        text-align: center;

        @include tv-m {
            max-width: 235px;
        }

        @include tv-l {
            max-width: 550px;
        }

        label {
            font-weight: normal;
            font-size: 18px;

            @include tv-m {
                font-size: 22px;
            }

            @include tv-l {
                font-size: 28px;
            }

            line-height: 20px;
            color: #004f39;
            position: relative;
            word-wrap: break-word;
        }

        &--height-90 {
            label {
                --height: 90px;
            }
        }

        &--height-100 {
            label {
                --height: 100px;
            }
        }

        &--height-125 {
            label {
                --height: 125px;
            }
        }

        &--height-140 {
            label {
                --height: 140px;
            }
        }

        &--height-145 {
            label {
                --height: 145px;
            }
        }

        &--height-155 {
            label {
                --height: 155px;
            }
        }

        &--height-295 {
            label {
                --height: 295px;
            }
        }

        &--height-320 {
            label {
                --height: 320px;
            }
        }

        &--height-430 {
            label {
                --height: 430px;
            }
        }

        &--height-250 {
            label {
                --height: 250px;
            }
        }

        @include tablet-l {
            &--height-145 {
                label {
                    --height: 110px;
                }
            }

            &--height-125 {
                label {
                    --height: 90px;
                }
            }

            &--height-295 {
                label {
                    --height: 215px;
                }
            }

            &--height-320 {
                label {
                    --height: 235px;
                }
            }

            &--height-430 {
                label {
                    --height: 315px;
                }
            }

            &--height-155 {
                label {
                    --height: 115px;
                }
            }

            &--height-90 {
                label {
                    --height: 60px;
                }
            }

            &--height-100 {
                label {
                    --height: 70px;
                }
            }

            &--height-250 {
                label {
                    --height: 177px;
                }
            }

            &--height-140 {
                label {
                    --height: 105px;
                }
            }
        }

        @include tablet-s {
            &--height-140 {
                label {
                    --height: 105px;
                }
            }

            &--height-90 {
                label {
                    --height: 68px;
                }
            }

            &--height-140 {
                label {
                    --height: 132px;
                }
            }
        }

        @include tv-m {
            &--height-430 {
                label {
                    --height: 770px;
                }
            }
            &--height-320 {
                label {
                    --height: 577px;
                }
            }

            &--height-295 {
                label {
                    --height: 485px;
                }
            }

            &--height-125 {
                label {
                    --height: 175px;
                }
            }

            &--height-145 {
                label {
                    --height: 211px;
                }
            }

            &--height-140 {
                label {
                    --height: 200px;
                }
            }

            &--height-250 {
                label {
                    --height: 420px;
                }
            }

            &--height-90 {
                label {
                    --height: 134px;
                }
            }

            &--height-155 {
                label {
                    --height: 214px;
                }
            }
        }

        @include tv-l {
            &--height-430 {
                label {
                    --height: 1345px;
                }
            }

            &--height-320 {
                label {
                    --height: 1000px;
                }
            }

            &--height-295 {
                label {
                    --height: 800px;
                }
            }

            &--height-125 {
                label {
                    --height: 300px;
                }
            }
            &--height-145 {
                label {
                    --height: 345px;
                }
            }

            &--height-140 {
                label {
                    --height: 340px;
                }
            }

            &--height-250 {
                label {
                    --height: 628px;
                }
            }

            &--height-100 {
                label {
                    --height: 147px;
                }
            }

            &--height-90 {
                label {
                    --height: 121px;
                }
            }

            &--height-155 {
                label {
                    --height: 341px;
                }
            }
        }

        &--top {
            &__35-540 {
                top: 35px;
                right: 540px;
            }

            &__35-300 {
                top: 35px;
                right: 300px;
            }

            &__35-820 {
                top: 35px;
                right: 820px;
            }

            &__35-980 {
                top: 35px;
                right: 980px;
            }

            &__35-1180 {
                top: 35px;
                right: 1180px;
            }

            label::before {
                top: 100%;
            }

            label::after {
                top: calc(100% + var(--height));
            }
        }

        @include tablet-l {
            &--top {
                &__35-300 {
                    top: 35px;
                    right: 255px;
                }
            }

            &--top {
                &__35-540 {
                    top: 35px;
                    right: 480px;
                }
            }

            &--top {
                &__35-820 {
                    top: 35px;
                    right: 725px;
                }
            }

            &--top {
                &__35-980 {
                    top: 35px;
                    right: 845px;
                }
            }

            &--top {
                &__35-1180 {
                    top: 35px;
                    right: 1030px;
                }
            }
        }

        @include tablet-s {
            &--top {
                &__35-300 {
                    top: 35px;
                    right: 205px;
                }
            }

            &--top {
                &__35-540 {
                    top: 35px;
                    right: 381px;
                }
            }

            &--top {
                &__35-820 {
                    top: 35px;
                    right: 565px;
                }
            }

            &--top {
                &__35-980 {
                    top: 35px;
                    right: 646px;
                }
            }

            &--top {
                &__35-1180 {
                    top: 25px;
                    right: 805px;
                }
            }
        }

        @include tv-m {
            &--top {
                &__35-1180 {
                    top: 18px;
                    right: 2240px;
                }
            }
            &--top {
                &__35-980 {
                    top: 18px;
                    right: 1805px;
                }
            }

            &--top {
                &__35-820 {
                    top: 35px;
                    right: 1506px;
                }
            }

            &--top {
                &__35-540 {
                    top: 35px;
                    right: 960px;
                }
            }

            &--top {
                &__35-300 {
                    top: 35px;
                    right: 480px;
                }
            }
        }

        @include tv-l {
            &--top {
                &__35-1180 {
                    top: 15px;
                    right: 3380px;
                }
            }

            &--top {
                &__35-980 {
                    top: 18px;
                    right: 2680px;
                }
            }

            &--top {
                &__35-820 {
                    top: 35px;
                    right: 2250px;
                }
            }

            &--top {
                &__35-540 {
                    top: 35px;
                    right: 1430px;
                }
            }

            &--top {
                &__35-300 {
                    top: 35px;
                    right: 650px;
                }
            }
        }

        &--bottom {
            &__70-150 {
                bottom: 70px;
                right: 150px;
            }

            &__70-200 {
                bottom: 70px;
                right: 150px;
            }

            &__70-315 {
                bottom: 70px;
                right: 315px;
            }

            &__70-445 {
                bottom: 70px;
                right: 445px;
            }

            &__70-705 {
                bottom: 70px;
                right: 705px;
            }

            &__70-880 {
                bottom: 70px;
                right: 880px;
            }

            &__70-1080 {
                bottom: 70px;
                right: 1080px;
            }

            @include tablet-l {
                &__70-150 {
                    bottom: 70px;
                    right: 135px;
                }

                &__70-315 {
                    bottom: 70px;
                    right: 277px;
                }

                &__70-445 {
                    bottom: 70px;
                    right: 387px;
                }

                &__70-705 {
                    bottom: 70px;
                    right: 622px;
                }

                &__70-880 {
                    bottom: 70px;
                    right: 767px;
                }

                &__70-1080 {
                    bottom: 70px;
                    right: 945px;
                }
            }

            @include tablet-s {
                &__70-150 {
                    bottom: 70px;
                    right: 97px;
                }

                &__70-315 {
                    bottom: 55px;
                    right: 225px;
                }

                &__70-445 {
                    bottom: 70px;
                    right: 306px;
                }

                &__70-705 {
                    bottom: 70px;
                    right: 483px;
                }

                &__70-880 {
                    bottom: 70px;
                    right: 590px;
                }

                &__70-1080 {
                    bottom: 46px;
                    right: 728px;
                }
            }

            @include tv-m {
                &__70-1080 {
                    bottom: 70px;
                    right: 2000px;
                }

                &__70-880 {
                    bottom: 57px;
                    right: 1615px;
                }

                &__70-705 {
                    bottom: 70px;
                    right: 1297px;
                }

                &__70-445 {
                    bottom: 70px;
                    right: 797px;
                }

                &__70-315 {
                    bottom: 78px;
                    right: 540px;
                }

                &__70-150 {
                    bottom: 70px;
                    right: 200px;
                }
            }

            @include tv-l {
                &__70-1080 {
                    bottom: 70px;
                    right: 3104px;
                }

                &__70-880 {
                    bottom: 67px;
                    right: 2503px;
                }

                &__70-705 {
                    bottom: 74px;
                    right: 1989px;
                }

                &__70-445 {
                    bottom: 70px;
                    right: 1212px;
                }

                &__70-315 {
                    bottom: 70px;
                    right: 861px;
                }

                &__70-150 {
                    bottom: 70px;
                    right: 300px;
                }
            }

            label::before {
                bottom: 100%;
            }

            label::after {
                bottom: calc(100% + var(--height));
            }
        }
    }
}
