.amenities {
    width: 100%;
    height: 100%;
    background-color: #b6b8dd;
    margin: 0;
    padding: 0;
    position: relative;

    &-content {
        padding: 60px 60px 120px 60px;
        position: relative;
        height: 100% !important;
        @include desktop-extra-large() {
            padding: 80px 80px 120px 80px;
        }

        @include tablet-max {
            padding: 30px 60px 120px 60px;
        }

        @include tablet-res-height {
            padding: 10px 30px;
            height: 100%;
        }

        @include tablet-large-max {
            padding: 215px 60px 120px 60px;
        }

        @include mobile-large {
            padding: 0;
            transform: translateY(0);
        }
        &__img {
            max-width: 100%;
            height: 100%;
            min-width: 100px;
            margin: 0 auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @include ipad-pro {
                max-height: 600px;
                margin-top: 40%;
            }
            @include tablet-max {
                height: auto;
            }
            @include tablet-mini {
                max-height: 440px;
            }
            @include mobile-large {
                max-height: 240px;
                margin-top: 30%;
                height: 100%;
            }

            @include screen-tv {
                max-height: 100%;
                margin-top: 0;
            }

            img {
                max-width: 100%;
                object-fit: cover;
                display: block;
                margin: 0 auto;
                margin-left: -10px;

                @include tablet-max {
                    object-fit: cover;
                }

                @include tablet-res-height {
                    max-height: 300px;
                }
            }
            &__tall {
                @include desktop-extra() {
                    max-width: 25%;
                }
                @include desktop-extra-large() {
                    max-width: 30%;
                }
                @include mobile-large {
                    width: 50% !important;
                }
            }
        }
        &__video {
            height: 100%;
        }
        iframe {
            width: 100%;
            height: 100%;
        }
        &__sub {
            position: absolute;
            width: 40%;
            height: auto;
            bottom: 0;
            background-color: #5e0f16;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin-left: -10px;

            @include tablet-res-height {
                bottom: 0px;
                padding: 12px 20px;
            }

            @include mobile-large {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 5px;
            }
            p {
                font-size: 10px;
                color: #b6b8dd;
                margin: 0 !important;
                line-height: 20px;
                @include desktop-extra-large() {
                    font-size: 14px;
                }
            }
            p:first-child {
                font-size: 14px;
                color: #b6b8dd;
                margin: 0 !important;
                padding-bottom: 2px;
                @include desktop-extra-large() {
                    font-size: 18px;
                }
            }

            @include tablet-max {
                width: 100%;
            }
        }
        &__sub__tall {
            left: 50%;
            transform: translate(-50%, 0);
            @include desktop-extra() {
                width: 25%;
            }
            @include desktop-extra-large() {
                width: 30%;
            }
            @include mobile-large {
                width: 50%;
            }
        }
    }
    &-diff__content {
        transform: translateY(0);
    }
    .slick {
        &-slider,
        &-list,
        &-track,
        &-active {
            height: 100% !important;
        }

        &-slide {
            & > div {
                height: 100%;
            }

            img {
                height: 100%;
            }
        }
    }

    .slick-initialized .slick-slide {
        border: none;
    }
    &-btn-prev {
        position: absolute;
        bottom: 40px;
        left: -85px;
        width: 20px;
        height: 20px;

        @include tablet-max {
            bottom: 126px;
            left: -62px;
        }

        @include mobile {
            bottom: 83px;
        }
    }
    &-btn-next {
        position: absolute;
        bottom: 40px;
        right: -45px;
        width: 20px;
        height: 20px;

        @include tablet-max {
            bottom: 126px;
            right: -38px;
        }

        @include mobile {
            bottom: 83px;
        }
    }

    .amenities-btn__slider {
        text-align: center;
        display: block;
        margin-left: auto;
        cursor: pointer;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background-color: #ffffff;
        border: none;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);
        svg {
            margin-top: 6px;
        }
        svg > path {
            fill: #5e0f16;
        }
        @include mobile-large {
            width: 35px;
            height: 35px;
        }
    }
    .amenities-btn__slider__right {
        margin-left: 0;
        margin-right: auto;
    }
    .slider-img__sub {
        width: 144px;
        height: 87px;
    }
    .slick-slider {
        height: 100%;
        overflow: hidden;
    }

    .amenities__slider {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 920px;
        height: 80px;

        @include mobile {
            bottom: -20px;
        }

        @include tablet-max {
            height: 150px;
            width: 750px;
        }
        @include tablet-mini {
            height: 150px;
            width: 550px;
        }

        @include mobile {
            height: 110px;
            width: 225px;
        }

        @include tablet-res-height {
            height: 0;
        }

        transform: translate(-50%, 0);
        .slick-current {
            height: 96%;
            offset: 0px 2px rgba(0, 0, 0, 0.25);
            img {
                border: 3px solid #b3e4f4;
            }
        }
        .slick-slide {
            img {
                width: 127px;
                height: 80px;
                cursor: pointer;
                object-fit: cover;

                @include tablet-max {
                    width: 86px;
                    height: 60px;
                }

                @include mobile {
                    width: 68px;
                    height: 52px;
                }
            }

            @include tablet-res-height {
                display: none;
            }
        }
    }
}
