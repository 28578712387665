.animation-line-floor {
    stroke-dasharray: 1900;
    animation: dash 5s infinite;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1900;
    }
    to {
        stroke-dashoffset: 0;
    }
}
