.login {
    width: 100%;
    height: 100%;
    background-color: #243670;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &__form-login {
        text-align: center;
        overflow: hidden;
        width: 40%;
        height: 75%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        border-radius: 50px;
        border: 1px solid #5e0f16;
        overflow: auto;
        @include scroll-custom {
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                margin-block-start: 50px;
                margin-block-end: 50px;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
        }
        @media screen and (max-width: 1440px) {
            width: 60%;
            height: 90%;
        }

        @include screen-ipad-pro {
            width: 90%;
            height: fit-content;
        }

        @include tablet-res-height{
            height: 100%;
            overflow-y: auto;
        }

        @include screen-tv {
            display: flex;
            align-items: center;
            width: 30%;
            height: 40%;
        }
    }
    &__icon-close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }
    &__content-form {
        width: 60%;
        margin: 50px auto;

        @include mobile-large {
            width: 85%;
            margin: 30px auto;
        }

        .block-content {
            color: #5e0f16;
            &__top {
                width: 90%;
                margin: 0 auto;
                span {
                    font-family: Lausanne;
                    font-size: 18px;
                    line-height: 32px;
                    text-transform: uppercase;
                    margin: 0;

                    @include tablet-max {
                        line-height: 24px;
                    }

                    @include mobile-large {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                &__title {
                    font-family: Lausanne;
                    font-size: 56px;
                    line-height: 44px;
                    text-transform: uppercase;
                    padding: 20px 0;
                    margin: 0;
                    color: #5e0f16;

                    @include tablet-max {
                        font-size: 48px;
                        line-height: 55px;
                    }

                    @include mobile-large {
                        font-size: 35px;
                        line-height: 45px;
                        padding: 10px 0;
                    }
                }
                &__desc {
                    font-size: 17px;
                    line-height: 20px;
                    margin: 0;
                    a {
                        color: #5e0f16;
                        text-decoration: underline;
                    }
                    span {
                        display: inline-block;
                        font-family: Cucina;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                    }
                    @include tablet-max {
                        font-size: 18px;
                    }

                    @include mobile-large {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
            input {
                // padding-right: 30px;
                @include mobile-large {
                    margin-top: 20px;
                }
            }
            .icon-show {
                position: absolute;
                top: 40px;
                right: 0;
                height: calc(100% - 40px);
                @include mobile-large {
                    top: 20px;
                    right: 0;
                    height: calc(100% - 20px);
                }
                img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 15px;
                    right: 5px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
