.button {
    font-family: "Proxima";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 8px 18px;
    margin-bottom: 10px;
    cursor: pointer;
    background: #ef684d;
    border-radius: 12px;

    &:hover {
        box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }

    &__btn--delete {
        background: #252235;
        border-radius: 10px;
        @include mobile-large {
            margin-right: 10px;
        }
    }

    &__btn--disabled {
        cursor: no-drop;
        opacity: 0.65;

        &:hover {
            box-shadow: none;
        }
    }
}
