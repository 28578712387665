$desktop-width: 1440px;
$tablet-width: 1366px;
$tv_m-width: 2560px;
$tv_xl-width: 3896px;

@mixin tv {
    @media only screen and (min-width: #{$tv_m-width}) and (max-width: #{$tv_xl-width}) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}

.gallery {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    .frames-view {
        position: relative;
        height: 100%;
        max-width: 100%;
        width: fit-content;
        margin: auto;
        @include tablet-max {
            max-width: none;
            padding-right: 100px;
        }
        @include mobile-large {
            max-width: none;
            padding-right: 50px;
        }
        @include tablet-res-height {
            padding-right: 0px;
            margin-top: -20px;
        }
    }

    .gallery__wrap__img {
        width: 100%;
        max-height: 100%;
        overflow-x: hidden;
        @include tablet-max {
            overflow-x: auto;
            @include scroll-hidden;
        }

        @include tablet-res-height {
            display: flex;
            justify-content: center;
        }
    }
    &__img {
        max-width: 100%;
        height: calc(100vh - 110px);
        max-height: 100%;
        object-fit: cover;
        margin: 0 auto;
        @include screen-ipad-pro {
            height: calc(100vh - #{$height-mobile-menu});
            width: auto;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }

        &__second {
            width: 100%;
            height: calc(100vh - #{$height-menu-responsive});
            object-fit: contain;
            @include desktop-extra-large {
                height: calc(100vh - #{$height-menu});
            }
            @include desktop-extra-middle {
                height: calc(100vh - #{$height-menu-responsive});
            }
            @include screen-ipad-pro {
                height: calc(100vh - #{$height-mobile-menu});
                width: auto;
            }
        }
    }

    &__img--inframe {
        max-width: 100%;
        width: auto;
        margin: auto;
        height: 100%;
        @include tablet-max {
            max-width: none;
        }
    }

    &__button {
        // width: 362px;
        // height: 41px;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // position: absolute;
        // bottom: 21px;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 10;

        @include desktop {
            width: 300px;
        }

        @include tablet {
            width: 200px;
        }

        &__item {
            width: 41px;
            height: 41px;
            position: absolute;
            top: 50%;

            &.prev {
                left: 5%;
            }

            &.next {
                right: 5%;
            }

            @include tv {
                // width: 71px;
                // height: 170%;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }

            background-color: #fff;
            outline: none;
            border: none;
            border-radius: 50%;
            box-shadow: 0px 6px 10px 0px #00000029;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .frames {
        &__note {
            font-family: "Lausanne";
            .dots {
                position: absolute;
                left: 0;
                top: 0;
                height: 10px;
                width: 10px;
                border-radius: 100%;
                background-color: #004f39;
                border: 1px solid #fff;
                transform: translate(-50%, -50%);
            }

            &--label {
                position: absolute;
                max-width: 100px;
                transform: translate(-50%, -50%);
                left: 0;
                width: 150px;
                min-width: 150px;
                font-family: "Lausanne";
                font-size: 12px;
                line-height: 1.2;

                @include tablet-res-height {
                    font-size: 10px;
                }
            }

            &--line {
                position: absolute;
                left: 0;
                width: 1px;
                background-color: #004f39;
            }
        }
    }
}
